/* THIS IS FILE IS AUTO-GENERATED BY graphql-codegen. DO NOT EDIT THIS FILE DIRECTLY! */
/* eslint-disable @typescript-eslint/no-unused-vars, no-restricted-syntax */

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as Types from '../../types/definitions/vendor/graphql-schema.d';

const defaultOptions = {} as const;

export type GetFeatureFlagsQueryVariables = Types.Exact<{
  flags: Types.FeatureFlagInput[] | Types.FeatureFlagInput;
  includeClient: Types.Scalars['Boolean'];
  includeVisitor: Types.Scalars['Boolean'];
}>;

export type GetFeatureFlagsQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    featureFlags: {
      __typename?: 'FeatureFlag';
      name: string;
      enabled: boolean;
    }[];
  } | null;
  visitor?: {
    __typename?: 'Visitor';
    id: string;
    featureFlags: {
      __typename?: 'FeatureFlag';
      name: string;
      enabled: boolean;
    }[];
  };
};

export const GetFeatureFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFeatureFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'flags' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FeatureFlagInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeClient' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeVisitor' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeClient' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureFlags' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'flags' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visitor' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeVisitor' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureFlags' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'flags' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *      flags: // value for 'flags'
 *      includeClient: // value for 'includeClient'
 *      includeVisitor: // value for 'includeVisitor'
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeatureFlagsQuery,
    GetFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(
    GetFeatureFlagsDocument,
    options,
  );
}
export function useGetFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureFlagsQuery,
    GetFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetFeatureFlagsQuery,
    GetFeatureFlagsQueryVariables
  >(GetFeatureFlagsDocument, options);
}
export type GetFeatureFlagsQueryHookResult = ReturnType<
  typeof useGetFeatureFlagsQuery
>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useGetFeatureFlagsLazyQuery
>;
export type GetFeatureFlagsQueryResult = Apollo.QueryResult<
  GetFeatureFlagsQuery,
  GetFeatureFlagsQueryVariables
>;
