import { useGetFeatureFlagsQuery } from './getFeatureFlags';
import { FeatureFlag } from '../../types/definitions/vendor/graphql-schema.d';

export type FeatureFlagContext = 'client' | 'visitor';
export type FeatureFlagNames = string | string[];
export type GraphQLFeatureFlags = FeatureFlag[];
export type FeatureFlags = Record<string, boolean>;

type GraphQLQueryOptions = Parameters<typeof useGetFeatureFlagsQuery>[number];
type UseFeatureFlagQueryOption = Partial<
  Pick<GraphQLQueryOptions, 'onCompleted' | 'onError' | 'skip'>
>;

const toFeatureFlags = (graphQLFFs?: GraphQLFeatureFlags): FeatureFlags => {
  if (!graphQLFFs) {
    return {};
  }

  return graphQLFFs.reduce((featureFlags, { name, enabled }) => {
    return {
      ...featureFlags,
      [name]: enabled,
    };
  }, {} as FeatureFlags);
};

/**
 * Retrieves the state of the specified feature flag(s)
 * @param context The context of the desired feature flag(s)
 * @param names The name(s) of the desired feature flag(s)
 * @param options GraphQL query options
 */
export const useFeatureFlags = (
  context: FeatureFlagContext,
  names: FeatureFlagNames,
  options: UseFeatureFlagQueryOption = {},
) => {
  const flagNames = Array.isArray(names) ? names : [names];

  // Ideally we'd have two separate hooks, `useGetClientFeatureFlagsQuery` &
  // `useGetVisitorFeatureFlagsQuery` that retrieve the `client` & `visitor`
  // feature flags, respectively. Then we could call one vs the other depending
  // on the `context`. However, we cannot call Hooks conditionally. So instead
  // of calling two separate Hooks and then deciding which data we'll use based
  // on the `context`, we call `useGetFeatureFlagsQuery` that queries both, but
  // conditionally (using `includeClient` & `includeVisitor`). Then we return
  // the data for the requested `context`.
  const { data, loading, error } = useGetFeatureFlagsQuery({
    ...options,
    variables: {
      flags: flagNames.map(name => ({ name })),
      includeClient: context === 'client',
      includeVisitor: context === 'visitor',
    },
  });

  // The `visitor` data will always exist when requested, but the `client` data
  // could be `null` when requested if the user isn't logged in. In which case
  // we'll return an empty object
  const featureFlags = data
    ? toFeatureFlags(data[context]?.featureFlags)
    : undefined;

  return { loading, featureFlags, error };
};
